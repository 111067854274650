import React, { useState } from 'react';

const Invisible_Nonclickable = ({ title, onButtonClick }) => {
    const [isChecked, setIsChecked] = useState(false);

    return (
        <div
            className={`w-[95%] h-[40px] m-auto flex gap-1`}
            style={{ transition: 'border 300ms' }}
        >
            <div 
                className={`h-[40px] w-[177px] rounded-[5px] flex items-center justify-center px-2 gap-[8px] ease-in-out duration-300
                ${isChecked ? 'border-[#c8102e] border-2' : 'border-transparent'}
                ${isChecked ? 'bg-white text-main-red' : 'bg-transparent text-transparent'}`}
            >
           
            </div>
        </div>
    );
};

export default Invisible_Nonclickable;