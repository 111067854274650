import React from "react";

export default function NavIcon() {
    return (
        <>
        <div className="h-[73px] w-[100%]  flex items-center justify-center overflow-hidden ">
            <img src="../../img/logo.png" className="h-[50px]" alt="" />
            <div className="w-[12px]"></div>
            <img src="../../img/letterKetKet.png" className="h-[45px]" alt="" />
        </div>
        </>
    )
}