import React, { useEffect, useState } from 'react';

const Logos = () => {
  const [logos, setLogos] = useState([]);
  const [useStaticImage, setUseStaticImage] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch('https://www.api.ketket.fewnu.app/api/operations', {
          method: 'GET',
          headers: {
            'Cache-Control': 'no-cache'
          }
        });
        const data = await response.json();

        let fetchedLogos = [];
        if (data.data && Array.isArray(data.data)) {
          fetchedLogos = data.data;
          setUseStaticImage(false); // Use dynamic data
        } else {
          console.warn('No valid data found in response');
        }

        // Set logos to be fetched data or static data as needed
        setLogos(fetchedLogos.length ? fetchedLogos : getFallbackData());
      } catch (error) {
        console.error('Error fetching data:', error);
        // Use static images if there's an error
        setLogos(getFallbackData());
        setUseStaticImage(true); // Use static images
      }
    };

    fetchData();
  }, []);

  const getFallbackData = () => [
    '/img/parter/senelec.png',
    '/img/parter/baobab.png',
    '/img/parter/elton.png',
    '/img/parter/wave.png',
    '/img/parter/prem.png',
    '/img/parter/senelec.png',
    '/img/parter/baobab.png',
    '/img/parter/elton.png',
    '/img/parter/wave.png',
  ];

  const LOGOS = [
    { src: '/img/parter/senelec.png' },
    { src: '/img/parter/baobab.png' },
    { src: '/img/parter/elton.png' },
    { src: '/img/parter/wave.png' },
    { src: '/img/parter/prem.png' },
    { src: '/img/parter/senelec.png' },
    { src: '/img/parter/baobab.png' },
    { src: '/img/parter/elton.png' },
    { src: '/img/parter/wave.png' },
  ];
  
  

  return (
    <div className="flex flex-col gap-[10%] mt-[5.25%]  max-sm:mb-[15%]">
      <h4 className="text-h4 leading-h4 text-center font-arial font-bold text-main-blue w-[100%] h-[30px] mb-[2.5%] max-lg:w-[80%] max-sm:text-h5 max-sm:leading-h5 max-sm:mb-[5%]">
        {logos?.title ? logos.title : 'Les clients qui nous font confiance'}
      </h4>
      <div className="pause relative w-[100vw] h-[100px] mt-[0.5%] overflow-x-scroll">
      <div className="relative m-auto w-[100vw] overflow-hidden bg-white before:absolute before:left-0 before:top-0 before:z-[2] before:h-full before:w-[100px] before:bg-[linear-gradient(to_right,white_0%,rgba(255,255,255,0)_100%)] before:content-[''] after:absolute after:right-0 after:top-0 after:z-[2] after:h-full after:w-[100px] after:-scale-x-100 after:bg-[linear-gradient(to_right,white_0%,rgba(255,255,255,0)_100%)] after:content-['']">
  <div className=" flex gap-[25px]">
    {LOGOS.map((logo, index) => (
      <img
        className="flex w-[190px] h-[100px] items-center justify-center"
        key={index} 
        src={logo.src}
        alt={`Logo ${index + 1}`}
      />
    ))}
  </div>
</div>

      </div>
    </div>
  );
};

export default Logos;
