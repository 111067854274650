import React, { useEffect, useState } from 'react';
export default function About() {
    const [about, setAbout] = useState(null);

    useEffect(() => {
        // Fetch data from Laravel API
        fetch('https://www.api.ketket.fewnu.app/api/abouts', {
            method: 'GET',
            headers: {
                'Cache-Control': 'no-cache' // Prevent caching
            }
        })
        .then(response => response.json())
        .then(data => {
            // Check if the response contains valid data
            if (data.data && Array.isArray(data.data)) {
                const newestAbout = data.data[0] || null; // Take the first item if available
                setAbout(newestAbout);
            } else {
                console.warn('No valid data found in response');
            }
        })
        .catch(error => {
            console.error('Error fetching data:', error);
        });
    }, []);

    const aboutImage1 = about ? `http://localhost:8000/storage/${about.image1}` : '';
    const aboutImage2 = about ? `http://localhost:8000/storage/${about.image2}` : '';
    const aboutImage3 = about ? `http://localhost:8000/storage/${about.image3}` : '';
    const aboutImage4 = about ? `http://localhost:8000/storage/${about.image4}` : '';
    const aboutImage5 = about ? `http://localhost:8000/storage/${about.image5}` : '';


    console.log(about);

    return (
        
        <>
        <div className="about-title relative h-[50.8%] pt-[4.8%] w-[100%] flex place-content-evenly max-lg:mt-[30%] max-sm:mt-[40%] max-[400px]:mt-[50%] max-[330px]:mt-[60%]">
            <div className="squibbly-container w-[24%] h-[100%] max-lg:hidden">
                <div className="squib ml-[31%] mt-[20.25%] max-ml:mt-[40%]">
                  <img className="w-[38%]" src="../img/blue-vector.png" alt="" />
                </div>
            </div>
            <div className="relative w-[50%] m-auto  grid place-items-center max-lg:w-[86%]">
                <div className="text-center w-[60%] mt-[7.9%] font-arial font-bold text-h3 max-ml:w-[80%] max-sm:w-[95%] leading-h3 text-main-black max-ml:text-h3 max-ml:leading-h3 max-sm:text-h4 max-sm:leading-h4">
                {about?.title ? about.title : "Révolutionner le bien-être des salariés par le sport"}

                </div>
            </div>
            <div className="squibbly-containr w-[24%] h-[100%] max-lg:hidden">
                <div className="squib ml-[37%] mt-[20.25%] max-ml:mt-[80%]">
                <img className="w-[40%] -rotate-[30deg]" src="../img/blue-vector.png" alt="" />
                </div>
               
            </div>
        </div>
        
        
        <div className="about-container h-[100%] m-auto w-[88%] relative max-ml:w-[92.5%]  mt-[5.1%] max-lg:w-[100%]">
        <img className="absolute left-[15.5%] w-[9.5%] -top-[5.3%]" src="../img/blue-x.png" alt="" />
        <img className="absolute left-[83.9%] w-[9.5%] top-[29.3%] -rotate-[15deg]" src="../img/blue-x.png" alt="" />
        <img className="absolute left-[86.5%] w-[7%] top-[56.5%] -rotate-[0deg]" src="../img/red-x.png" alt="" />
        <img className="absolute left-[20%] w-[7%] top-[63%] rotate-[13deg]" src="../img/red-x.png" alt="" />

            <div className="about-blocks flex h-[45%] w-[100%] max-ml:pb-[5%] max-lg:flex-col max-lg:pb-[0%]  max-lg:space-y-[10%]   ">
                <div className="about-des1 w-[38%] ml-[4.8%] h-[100%] flex-col max-ml:w-[48.5%] max-ml:ml-[0] max-lg:w-[95%] max-lg:ml-auto">
                    <div className="text-h4 leading-h4 font-bold font-quicksand text-main-blue mt-[15.55%] ">
                    {about?.section1_title ? about.section1_title : "Ket Ket, C’est Quoi?"}
                                        </div>

                <div className="text-h6 font-quicksand leading-[28px] font-semibold w-[100%]  mt-[2.1%]">
                {about?.section1_content ? about.section1_content : "Ket Ket est une entreprise sénégalaise dédiée à l'amélioration du bien-être des salariés en simplifiant l'accès aux structures sportives amateurs, en proposant des solutions d'adhésion aux entreprises pour soutenir et financer la pratique sportive de leurs employés."}
                </div>

            </div>

            <div className="relative h-[45%] w-[48%] ml-[15.3%] mt-[0.15%] max-ml:w-[48.5%] max-ml:ml-auto max-lg:w-[100%] max-lg:m-auto">
            <img 
      className="macbook relative -ml-[0.7%] w-[91.5%] max-ml:mt-[5%] max-xl:mt-[10%] max-lg:m-auto" 
      src={aboutImage1 ? aboutImage1 : "../img/macbook.png" } 
      alt="Description of the image" 
    />          

            </div>

            </div>
            <div className="about-blocks flex h-[65%] w-[100%] pb-[5.25%]  max-lg:flex-col-reverse max-lg:min-h-[50%] max-lg:pb-0 max-lg:mt-[2.5%] ">
                <div className="image-bundles w-[35.1%] ml-[4.75%] mt-[0%] h-[77.5%] max-lg:flex max-lg:flex-col max-lg:gap-4  max-lg:w-[95%] max-lg:h-fit max-ml:w-[48.5%] max-ml:ml-[0] max-lg:m-auto max-lg:mt-[5%]">
                <div className="macbook relative min-h-[215px] mt-[5%] w-[88.95%] rounded-[20px] z-10 max-lg:min-h-[200px] max-lg:mx-auto  max-lg:w-[95%] max-lg:mt-0 "
     style={{
         backgroundImage: aboutImage2 ? `url(${aboutImage2})` : 'url(../img/about2.png)',
         backgroundSize: 'cover',
         backgroundPosition: 'center'
     }}>
</div>



                    <div  className=" relative macbook vertical-image min-h-[325px] w-[39%] -mt-[12%] ml-[4.9%] z-30 rounded-[20px] max-lg:w-[95%] max-lg:min-h-[200px] max-lg:mx-auto max-lg:-mt-[0%] "
                     style={{
                        backgroundImage: aboutImage3 ? `url(${aboutImage3})` : 'url(../img/about3.png)',
                        backgroundSize: 'cover',
                        backgroundPosition: 'center'
                    }}>
                        
                    </div>

                    <div  className="macbook vertical-image min-h-[170px] w-[51%] -mt-[39%] ml-[48%] z-6 rounded-[20px]  z-10 max-[1700px]:-mt-[35%] max-[1750px]:w-[53%] max-[1600px]:-mt-[38%] max-ml:-mt-[38%] max-xl:-mt-[43%] max-lg:w-[95%] max-lg:min-h-[200px]  max-lg:mx-auto max-lg:-mt-[0%] "
                     style={{
                        backgroundImage: aboutImage3 ? `url(${aboutImage3})` : 'url(../img/pairgloves.jpg)',
                        backgroundSize: 'cover',
                        backgroundPosition: 'center'
                    }}>

<img src="../img/red-circle.png"
       className="absolute  w-[15%] -mt-[12.5%] ml-[13%]  transform -translate-x-1/2 -translate-y-1/2 z-0 max-lg:hidden"
       alt="" />
                    
                    </div>

                </div>

                <div className="about-des1 w-[45%] mt-[1.55%] ml-[16.4%] h-[80%] flex-col max-ml:w-[48.5%] max-ml:ml-auto max-lg:w-[95%]">
                    <div className="text-h4 leading-[39px] font-bold font-quicksand text-main-blue mt-[16.55%] max-lg:ml-auto ">
                    {about?.section2_title ? about.section2_title : "Accès au sport & promotion d'un mode de vie actif"}
                                        </div>

                <div className="text-h6 font-quicksand leading-[28px] font-semibold w-[92%]  mt-[1.7%]">
                {about?.section2_content ? about.section2_content : "Ket Ket facilite l'accès des salariés à des installations sportives de qualité, encourage une pratique régulière du sport et soutient le financement des activités sportives par les entreprises pour promouvoir un mode de vie actif, réduire le stress et renforcer les liens sociaux."}
                </div>

            </div>

            </div>

            <div className="about-blocks mt-[0%] flex h-[45%] w-[100%] max-lg:flex-col max-lg:h-[100%] max-lg:mt-[5%]">
                <div className="about-des1 w-[50%] ml-[4.75%] h-[100%]  flex-col max-ml:w-[48.5%] max-ml:ml-[0] max-lg:w-[95%] max-lg:h-[40%] max-lg:ml-auto ">
                    <div className="text-h4 leading-[39px] font-bold font-quicksand text-main-blue mt-[20.55%]">
                    {about?.description ? about.description : "Bien-être global et développement personnel"}
                                        </div>

                <div className="text-h6 font-quicksand leading-[28px] font-semibold w-[90%] mt-[3.25%]">
                {about?.extra_info ? about.extra_info : "Chez Ket Ket, notre engagement est de promouvoir le bien-être des salariés par des programmes sportifs qui améliorent la santé physique, renforcent la confiance en soi et soutiennent le développement personnel pour une atmosphère de travail équilibrée et productive."}
                </div>

            </div>

            <div className="relative h-[45%] w-[48%] ml-[5%] mt-[1.5%] max-ml:w-[48.5%] max-ml:ml-auto max-ml:mt-[6%] max-xl:mt-[10%] flex flex-col max-lg:w-[100%] max-lg:m-auto max-lg:mt-[5%]">
            <img 
      className="macbook relative w-[91.5%] h-[90%] rounded-[20px] max-lg:m-auto" 
      src={aboutImage5 ? aboutImage5 : "../img/about5.png"} 
      alt="Description of the image" 
    />    
    <div className="h-[5%] -mt-[20%]">
     <img  className="ml-[72.5%] w-[35%]" src="../img/red-circle.png" alt="" />   
     </div>   
            </div>

            </div>
        </div>
            </>
    )}