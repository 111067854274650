import { Link } from "react-router-dom";

export default function NavBar() {
    return (
        <div className="Navbar  flex items-center max-[1200px]:w-[100%]">
            <div className="logo flex items-center">
            <img className="w-[78px] h-[63.7px]" src="../img/logo.png" alt="anyting" /> 
            <img src="../img/letterKetKet.png"  className=" w-[192px] h-[43px]" />
            </div>
            <div className="Navlinks relative ml-[5.5%] flex place-content-between text-h6 leading-h6  max-[1690px]:gap-[45px] max-2xl:gap-[30px] max-ml:gap-[20px] max-ml:text-base
            max-xl:left-8 max-[1200px]:hidden">
                <a href="#home">ACCUEIL</a>
                <a href="#about">QUI SOMMES-NOUS</a>
                <a href="#service">NOS SERVICES</a>
                <a href="#partenaire">PARTENAIRES</a>
            </div>
                <div className="ButtonContainer absolute left-[74.2%]  flex place-content-between 
                max-ml:w-[26.85%] max-ml:left-[72%] max-[1200px]:hidden">
           <Link
  to="/recherche"
  className="relative bg-main-blue border-2 border-[#1D428A] group hover:bg-white hover:text-[#1D428A] text-xl text-white w-[41.5%] font-light h-[100%] rounded-full flex justify-center items-center gap-[19px]"
>
  <svg
    className="w-[24px] h-[24px] max-ml:w-[20px] max-ml:h-[20px]  group-hover:fill-[#1D428A] group-hover:stroke-[#1D428A]"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M11 19C15.4183 19 19 15.4183 19 11C19 6.58172 15.4183 3 11 3C6.58172 3 3 6.58172 3 11C3 15.4183 6.58172 19 11 19Z"
      stroke="white"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M21 21L16.65 16.65"
      stroke="white"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
  <div className="relative right-1.5 bottom-[1px] max-[1690px]:text-lg max-2xl:text-base max-xl:text-sm">
    Recherche
  </div>
</Link>

              
              <button className="relative rendezvous-button-shadow bg-main-red text-white text-xl w-[52%] font-bold h-[100%] 
              rounded-[10px] flex justify-center items-center gap-[12px] max-[1690px]:text-lg max-2xl:text-base 
              max-xl:text-sm">
                 
             <div className="relative ">Prenez Rendez-Vous</div>
              </button>

                </div>    
                <div className="w-4 h-5 ml-auto">
                </div> 
                
        </div>
      )
}