import React, { useEffect, useState, useRef } from 'react';
import NavIcon from "../components/components_search_page/nav_icon";
import Search_main from "../components/components_search_page/search_main";
import Map from "../components/components_search_page/map";
import { Modal_search } from '../components/components_search_page/modal_search';
import { Modal_view } from '../components/components_search_page/modal_voir';

export default function SearchPage(handleCloseViewModal) {
    const [checkboxState, setCheckboxState] = useState({
        salleDeSport: false,
        football: false,
    });

    const [checkboxState_Activity, setCheckboxState_Activity] = useState({
        salleDeSport: false,
        football: false,
    });

    const onCheckboxChange_Activity = (id) => {
        setCheckboxState_Activity((prevState) => ({
            ...prevState,
            [id]: !prevState[id],
        }));
    };

    const onCheckboxChange = (id) => {
        setCheckboxState((prevState) => ({
            ...prevState,
            [id]: !prevState[id],
        }));
    };

    const toggleCheckboxState = (id) => {
        setCheckboxState((prevState) => ({
            ...prevState,
            [id]: !prevState[id],
        }));
    };

    const [selectedEnterprise, setSelectedEnterprise] = useState();
    const [showSearchModal, setShowSearchModal] = useState(false); // Show Modal_search
    const [showViewModal, setShowViewModal] = useState(false); // Show Modal_view

    const searchModalRef = useRef(null);
    const viewModalRef = useRef(null);

    const handleOpenSearchModal = () => setShowSearchModal(true);
    const handleCloseSearchModal = () => setShowSearchModal(false);

    useEffect(() => {
        // Prevent body scrolling when a modal is open
        if (showSearchModal || showViewModal) {
            document.body.style.overflow = 'hidden';
        } else {
            document.body.style.overflow = 'hidden';
        }

        return () => {
            document.body.style.overflow = '';
        };
    }, [showSearchModal, showViewModal]);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (searchModalRef.current && !searchModalRef.current.contains(event.target)) {
                handleCloseSearchModal();
            }
            if (viewModalRef.current && !viewModalRef.current.contains(event.target)) {
                handleCloseViewModal();
            }
        };

        if (showSearchModal || showViewModal) {
            document.addEventListener('mousedown', handleClickOutside);
        }

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [showSearchModal, showViewModal]);

    return (
        <div className="overflow-hidden relative">
            {showSearchModal && (
                <div className="fixed inset-0 z-50 bg-[#1D428A] bg-opacity-25 flex justify-center">
                    <div className="mt-[4.85%]" ref={searchModalRef}>
                        <Modal_search 
                            checkboxState={checkboxState}
                            onCheckboxChange={onCheckboxChange}
                            onClose={handleCloseSearchModal} 
                            onCheckboxChange_Activity={onCheckboxChange_Activity}
                            checkboxState_Activity={checkboxState_Activity}
                        />
                    </div>
                </div>
            )}

            <div className="testing flex w-full h-full z-10 overflow-hidden">
                <div className="w-[37.5%] h-full">
                    <NavIcon />
                    <div className="testing">
                        <Search_main 
                            setSelectedEnterprise={setSelectedEnterprise} 
                            onOpenSearchModal={handleOpenSearchModal} 
                            checkboxState={checkboxState}
                            toggleCheckboxState={toggleCheckboxState}
                            checkboxState_Activity={checkboxState_Activity}
                        />
                    </div>
                </div>
                <div className="w-[62.5%] h-full z-10">
                    <Map 
                        latitude={selectedEnterprise?.latitude} 
                        longitude={selectedEnterprise?.longitude} 
                        entrepriseName={selectedEnterprise?.nom} 
                    />

                    <Modal_view
                    entrepriseName={selectedEnterprise?.nom} 
                     />

                </div>
            </div>
        </div>
    );
}
